<template>

    <v-card>
        <v-card-title>
           <span>Datos de Tercerizados</span>
    
            <v-divider  class="mx-4" inset vertical></v-divider>


            <v-flex md2 lg2 mx-4 pt-4>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
             </v-flex>
    
               <v-flex   xs5 sm4 md3 lg3>
              <v-text-field  justify-center
                                    v-model="search"
                                    append-icon="search"
                                    label="Busqueda"
                                    single-line
                                    hide-details
                                  ></v-text-field> 
    
              </v-flex>
    
           <v-spacer></v-spacer>
    
             <v-btn color="primary" dark @click="dialog=true">
                  <v-icon dark>add</v-icon>
                </v-btn>
    
            <v-btn color="success" class="mx-2" @click="listar()"><v-icon>autorenew</v-icon></v-btn>
    
    
    
        </v-card-title>
    
      <v-data-table
        :headers="headers"
        :search="search"
        :items="ArrayTercerizados"
        sort-by="creado"
        class="elevation-1 mx-2 my-2"
      >
       
        
        
    
    
        <template  v-slot:[`item.actions`]="{ item }">
          <v-icon color="green" small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon color="red" small @click="deleteItem(item)">delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Reset</v-btn>
        </template>
    
    
        <template v-slot:[`item.activo`]="{ item }">
            <v-icon>
               {{ item.activo==1 ? "done" : "label_off" }}
            </v-icon>
      </template>
    
    
    
    
    
       <template v-slot:[`item.creado`]="{ item }">
            <span>{{ getDate(item.creado) }}</span>
      </template>
    
      
    
    
    
      </v-data-table>
    
     <span>
      <v-icon color="red">warning</v-icon>Datos aqui solo van a ser modificados cuando se cambien credenciales en AVL</span>
       
       
            <v-dialog v-model="dialog" max-width="700px">
             
              <v-card>
    
                  <v-toolbar color="fondo1">
                  <v-toolbar-title class="white--text">
                    {{ formTitle }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon dark @click="dialog=false">close</v-icon>
                </v-toolbar>
    
    
    
                <v-card-text>
                  <v-container>
                     <v-row>

                      

                      <v-col cols="5">
                       <v-text-field v-model="editedItem.nombre_tercerizado"  prepend-icon="label" label="Nombre tercerizado"></v-text-field>
                      </v-col>
                    
                   
                      <v-col cols="5">
                       <v-text-field v-model="editedItem.servidor_avl"  prepend-icon="cloud" label="Servidor AVL"></v-text-field>
                      </v-col>
    
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.usr_avl" prepend-icon="face" label="Usr avl"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.pwd_avl"  prepend-icon="lock" label="Pwd avl"></v-text-field>
                      </v-col>
    
                      <v-col cols="12" sm="6" md="6">
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
                      </v-col>
    
                      
    
                       <v-col cols="12" sm="6" md="4">
                            <span>Estado tercerizado</span><br>
                            <input type="radio" :value="1" v-model="editedItem.activo"> Activo
                            <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
     
                       </v-col>
    
    
                   
                    </v-row>
                    
                  </v-container>
                </v-card-text>
    
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancelar
                  </v-btn>
                  <v-btn  color="primary" dark @click="save"> Guardar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    
    
            
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">deseas deshablitar tercerizado?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
    
       
       
        </v-card>
    
    </template>
    
    
    <script>
    
    import { mapState,mapMutations } from "vuex";
    
    
    import axios from 'axios';
    
      export default {
        data: () => ({
            id_pais_activo:'',
            search:'',
            dialog: false,
            dialogDelete: false,
            ArrayTercerizados:[],
            PaisesArray:[],
        

          headers: [
            { text:'nombre_tercerizado', value:'nombre_tercerizado'},
            { text: 'Servidor', value: 'servidor_avl' },
            { text: 'Usr avl', value: 'usr_avl' },
            { text: 'Pwd avl', value: 'pwd_avl' },
            { text: 'Pais', value: 'id_pais_ter.nombre_pais' },
            { text: 'Activo', value: 'activo' },
            { text: 'Creado', value: 'creado' },
            { text: 'Acciones', value: 'actions', sortable: false },
          ],
          editedIndex: -1,
          editedItem: {
                  nombre_tercerizado:'',
                  servidor_avl:'',
                  usr_avl: '',
                  pwd_avl: '',
                  id_pais_ter: '',
                  activo: 1
          },
          defaultItem: {
                 nombre_tercerizado:'',
                 servidor_avl:'',
                  usr_avl: '',
                  pwd_avl: '',
                  id_pais_ter: '',
                  activo: 1
          },
          

    
        }),
    
        computed: {
    
           ...mapState(['usuario', 'token']),
    
          formTitle () {
            return this.editedIndex === -1 ? 'Nuevo ' : 'Edicion'
          },
        },
    
        watch: {
          dialog (val) {
            val || this.close()
          },
          dialogDelete (val) {
            val || this.closeDelete()
          },
        },
    
        created () {
          this.listarPaises();
        },
    
        methods: {
    
            ...mapMutations(['mostrarLoading','ocultarLoading']),


          listarPaises () {

                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'Accediendo a datos'});

                    let me=this;

                    let RespuestaPaises=[];

                    axios.get('paises',  config
                    ).then(function(response){
                        console.log(response);
                        RespuestaPaises=response.data;

                        RespuestaPaises.map(function(x){
                            me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                        });

                        me.id_pais_activo=me.PaisesArray[0].value;

                        me.ocultarLoading();

                        me.listar();
                    
                        
                    }).catch(function(error){
                    console.log(error);
                    });




                },

         
         listar () {
    
             let config={headers:{token:this.token}};
             this.mostrarLoading({titulo:'accediendo a datos'});
              let me=this;
    
              axios.get(`tercerizados/${this.id_pais_activo}`, config
              ).then(function(response){
                  console.log(response);
                  me.ArrayTercerizados=response.data;
                 me.ocultarLoading();
              }).catch(function(error){
                console.log(error);
              });
    
          },
    
    
          editItem (item) {
            this.editedIndex = this.ArrayTercerizados.indexOf(item)
            this.editedItem = Object.assign({}, item);
    
            this.dialog = true
          },
    
          deleteItem (item) {
            this.editedIndex = this.ArrayTercerizados.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
          },
    
          deleteItemConfirm () {
           // this.ArrayTercerizados.splice(this.editedIndex, 1)
              let id=this.editedItem._id;
              let config={headers:{token:this.token}};
    
              this.mostrarLoading({titulo:'actualizando'});
              axios.put(`tercerizado/${id}`, 
                                    { 
                                        'activo':0
                                    },
                                    config
                                  ).then(response=>{
                                   
                                    this.closeDelete()
                                   // this.ocultarLoading();
                                    console.log(response);
                                    this.listar();
                                    this.ocultarLoading();
    
                                  }).catch(e=>{
                                        console.log(e)
                                  });
     
          },
    
          close () {
            this.dialog = false
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
          },
    
          closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1
            })
          },
    
          save () {
            if (this.editedIndex > -1) {
              //Object.assign(this.ArrayTercerizados[this.editedIndex], this.editedItem)
                //edicion de campos
    
    
                          let id=this.editedItem._id;
                          let config={headers:{token:this.token}};
                         this.mostrarLoading({titulo:'actualizando'});
    
                        axios.put(`tercerizado/${id}`, 
                                    { 
                                       'nombre_tercerizado':this.editedItem.nombre_tercerizado,
                                        'servidor_avl':this.editedItem.servidor_avl,
                                        'usr_avl': this.editedItem.usr_avl,
                                        'pwd_avl': this.editedItem.pwd_avl,
                                        'id_pais_ter': this.id_pais_activo,
                                        'activo': this.editedItem.activo
                                    },
                                    config
                                  ).then(response=>{
                                   
                                    this.close();
                                   this.ocultarLoading();
                                    console.log(response);
                                    this.listar();
    
                                  }).catch(e=>{
                                        console.log(e)
                                  });
    
            } else {
    
                //guardamos 
        
    
              let config={headers:{token:this.token}};
              this.mostrarLoading({titulo:'actualizando'});
              axios.post('nuevo-tercerizado',
                        { 

                            'nombre_tercerizado':this.editedItem.nombre_tercerizado,
                            'servidor_avl':this.editedItem.servidor_avl,
                            'usr_avl': this.editedItem.usr_avl,
                            'pwd_avl': this.editedItem.pwd_avl,
                            'id_pais_ter': this.id_pais_activo,
                            'activo': this.editedItem.activo
    
                           },
                   config
              ).then(response=>{
    
    
           
                this.close();
                console.log(response);
                this.listar();
               this.ocultarLoading();
    
              }).catch(e=>{
                    console.log(e)
              });
    
    
    
            }//guardar datos
           
          },
    
      
    
    
    
    
            getDate(evaluar) {
                let date = new Date(evaluar),
                year = date.getFullYear(),
                month = (date.getMonth() + 1).toString(),
                formatedMonth = (month.length === 1) ? ("0" + month) : month,
                day = date.getDate().toString(),
                formatedDay = (day.length === 1) ? ("0" + day) : day,
                hour = date.getHours().toString(),
                formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
                minute = date.getMinutes().toString(),
                formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
                second = date.getSeconds().toString(),
                formatedSecond = (second.length === 1) ? ("0" + second) : second;
              return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
            },
    
    
    
    
    
    
        },
      }
    </script>