<template>

<v-card>
    <v-card-title>
       <span> Geocercas</span>

        <v-divider  class="mx-4" inset vertical></v-divider>

           <v-flex md2 lg2 mx-4 pt-4>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
         </v-flex>

            <v-switch class="ml-5 mt-5"
            v-model="switchTipos"
            :label="LabelSwitch"
          ></v-switch>


        

         <v-flex  class="ml-6" xs5 sm4 md3 lg3>
          <v-text-field  justify-center
                                v-model="search"
                                append-icon="search"
                                label="Busqueda"
                                single-line
                                hide-details
                              ></v-text-field> 

          </v-flex>

            <v-spacer></v-spacer>

            <v-btn color="accent" dark @click="dialog=true">
              <v-icon dark>add</v-icon>
              
            </v-btn>

             <v-btn color="success" class="mx-2" @click="listar()"><v-icon>autorenew</v-icon></v-btn>

      </v-card-title>


  <v-data-table
    :headers="headers"
    :items="ArrayGeocercas"
    :search="search"
    sort-by="nombre_geocerca"
    class="elevation-1 mt-5 mx-1"
  >

       

     
    <template v-slot:[`item.actions`]="{ item }">
    
      <v-icon small class="mr-2" @click="editItem(item)" color="green" >edit</v-icon>
      <v-icon small @click="deleteItem(item)" color="red" >delete</v-icon>
    
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>


     <template v-slot:[`item.creado`]="{ item }">
        <span>{{ getDate(item.creado) }}</span>
  </template>

   <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==true ? "done" : "label_off" }}
        </v-icon>
  </template>


   <template v-slot:[`item.parada_autorizada`]="{ item }">
        <v-icon>
           {{ item.parada_autorizada==true ? "verified" : "dangerous" }}
        </v-icon>

        <span v-if="item.parada_autorizada==true">Autorizada</span>
        <span v-if="item.parada_autorizada==false">No autorizada</span>

  </template>


  </v-data-table>

 <v-dialog v-model="dialog" max-width="600px">

          <v-card>


               <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
                {{ formTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialog=false">close</v-icon>
            </v-toolbar>

            

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.nombre_geocerca" label="Nombre Geocerca"></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="6" md="6">
                        <span>Tipo</span><br>
                        <input type="radio" :value="true" v-model="editedItem.parada_autorizada"> autorizada
                        <input type="radio" :value="false" v-model="editedItem.parada_autorizada"> No autorizada
 
                   </v-col>

                     <v-col cols="12" sm="6" md="6">
                        <span>Estado</span><br>
                        <input type="radio" :value="1" v-model="editedItem.activo"> activo
                        <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
 
                   </v-col>


        
                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" dark @click="save">Guardar</v-btn>
            </v-card-actions>


          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Deseas eliminar la Geocerca?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
   
    </v-card>
</template>


<script>



import axios from 'axios';
import { mapMutations, mapState } from "vuex";



  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Nombre', value: 'nombre_geocerca' },
        { text: 'Tipo', value: 'parada_autorizada' },
        { text: 'activo', value: 'activo' },
        { text: 'creado', value: 'creado' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      ArrayGeocercas: [],
      RespuestaGeocercas: [],
      editedIndex: -1,
      editedItem: {
        nombre_geocerca: '',
        parada_autorizada: true,
        activo: 1,
        creado:  new Date(),
       
      },
      defaultItem: {
        nombre_geocerca: '',
        parada_autorizada: true,
        activo: 1,
        creado:  new Date(),
      },

       id_pais_activo:'',
       PaisesArray:[],
       search:'',

        switchTipos:true,
        LabelSwitch:'Autorizada',


    }),

    computed: {

     ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nueva geocerca' : 'Edicion geocerca'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

       switchTipos(val){
          if(val==true){
             this.LabelSwitch='Autorizada';
             this.ActulizarTabla();
          }else{
               this.LabelSwitch='No Autorizada';
                this.ActulizarTabla();
          }
      }

    },

    created () {
      this.listarPaises();
    },

    methods: {

             ...mapMutations(['mostrarLoading','ocultarLoading']),



             
      listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listar();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


           },



            listar(){

                 this.ArrayGeocercas=[];
                 this.RespuestaGeocercas=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`geocercas/${this.id_pais_activo}`,
                    config
                    ).then(function(response){
                        console.log(response);
                        me.RespuestaGeocercas=response.data;

                        me.RespuestaGeocercas.map(function(x){

                          if(x.parada_autorizada==me.switchTipos){
                             me.ArrayGeocercas.push(x);
                          }

                        });



                       
                        me.ocultarLoading();
                    }).catch(function(error){ console.log(error); });

          },



      editItem (item) {
        this.editedIndex = this.ArrayGeocercas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayGeocercas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {

             let id=this.editedItem._id;
            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'accediendo a datos'});
              
             axios.put(`geocerca/${id}`, 
                                {'activo':0},
                                config
                              ).then(response=>{
                               
                                 this.closeDelete();
                                 this.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                              });

       
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
    
          //edicion 
                 this.mostrarLoading({titulo:'accediendo a datos'});
                 let id=this.editedItem._id;
                 let config={headers:{token:this.token}};

             axios.put(`geocerca/${id}`, 
                                { 
                                  'nombre_geocerca':this.editedItem.nombre_geocerca,
                                  'parada_autorizada':this.editedItem.parada_autorizada,
                                  'activo':this.editedItem.activo
                                },
                            config
                              ).then(response=>{
                               
                                this.close();
                                this.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                              });




        } else {
       
          //ingreso de gecoerca

              //Guardamos datos

              this.mostrarLoading({titulo:'accediendo a datos'});
              let config={headers:{token:this.token}};
            axios.post('nueva-Geocerca',
                    { 
                      'nombre_geocerca':this.editedItem.nombre_geocerca,
                      'parada_autorizada':this.editedItem.parada_autorizada,
                      'id_geocerca_pais':this.id_pais_activo,
                      'activo':this.editedItem.activo
                       },
              config
          ).then(response=>{


         
          
            this.close();
            console.log(response);
            this.listar();
             this.ocultarLoading();

          }).catch(e=>{
                console.log(e)
          });






        }
     
      },


    getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
  },


  ActulizarTabla(){

    let me=this;

     me.ArrayGeocercas=[];

     me.RespuestaGeocercas.map(function(x){
          if(x.parada_autorizada==me.switchTipos){
              me.ArrayGeocercas.push(x);
          }

        });

  }






    },
  }
</script>