<template>
  <v-card>
    <v-card-title>
       <span> Vehiculos</span>

        <v-divider  class="mx-4" inset vertical></v-divider>

        <v-flex md2 lg2 mx-4 pt-4>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
      </v-flex>


       <v-flex md2 lg2 mx-4 pt-4>
                        <v-select v-model="id_subflota_ver" dense 
                        outlined :items="arrayItemSubflotas" @change="FiltrarVehiculos()"  label="SubFlota"></v-select>
      </v-flex>

       <v-flex md2 lg2 mx-4 pt-4>
                        <v-select v-model="id_estado" dense 
                        outlined :items="EstadoArray" @change="FiltrarVehiculos()"  label="Estado"></v-select>
      </v-flex>



     
       <v-btn dark color="indigo" class="mx-5" @click="ActualizarGrid()"><v-icon>autorenew</v-icon></v-btn>

      <v-text-field class="mx-5"
        v-model="search"
        append-icon="search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="Arrayflota"
      :search="search"
       sort-by="nombre"
    >


    <template v-slot:[`item.id_avl`]="{ item }">
      <v-icon color="info" @click="CambiarOdometroActivo(item)">settings</v-icon>
      <span>{{item.id_avl}}</span>
     </template>
    
    
    <template v-slot:[`item.icono`]="{ item }">
        <img  :src="CambiarImgUrl(item.icono)" v-bind:alt="item.icono"   style="width: 30px; height: 30px"/>
        <v-icon  v-show="item.estado=='Taller'" small  @click="AbrirDetalleMantto(item)">home_repair_service</v-icon>
     </template>


      <template v-slot:[`item.odometro_hw`]="{ item }">
        
        <v-chip v-show="item.tipo_odo_activo=='Hardware'" class="ma-2" color="green" text-color="white">{{item.odometro_hw}}</v-chip>
        <span v-show="item.tipo_odo_activo=='Software'">{{item.odometro_hw}}</span>
        <v-btn v-show="item.observacion!=''" @click="VerDetalle(item)">{{item.observacion}}</v-btn>

     </template>

     <template v-slot:[`item.odometro_sw`]="{ item }">
         <v-chip v-show="item.tipo_odo_activo=='Software'" class="ma-2" color="red" text-color="white">{{item.odometro_sw}}</v-chip>
        
         <span v-show="item.tipo_odo_activo=='Hardware'">{{item.odometro_sw}}</span>
     </template>



<template v-slot:[`item.id_conductor`]="{ item }">
      <v-icon color="info" @click="ViewCambiarpiloto(item)" small>edit</v-icon>
      <span>{{item.id_conductor.nombre_conductor}}</span>
     </template>


        <template v-slot:[`item.expiracion_circulacion`]="{ item }"> 
       <span v-if="item.expiracion_circulacion">{{ConvertirFecha(item.expiracion_circulacion)}} </span>
         <v-icon  v-if="item.expiracion_circulacion" color="red">{{ evaluarVencimineto(item.expiracion_circulacion)}}</v-icon>
     </template>
     

                               
                              
</v-data-table>

  <span>
  <v-icon color="red">error</v-icon>circulaciones con vencimiento menores a 30 dias</span>


<v-dialog v-model="dialog" max-width="550px">
   <v-card>

     <v-toolbar dark color="fondo1">
       <v-toolbar-title> Actualizacion vehiculo:{{editedItem.nombre}}</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-icon dark @click="dialog=false">close</v-icon>
     </v-toolbar>

        <v-card-text class="text--center">

           <v-container>

                  <span><strong>Vehiculo:</strong> {{ editedItem.nombre }} <strong>Placa:</strong> {{ editedItem.placa }} </span>
                        
                <v-row class="mt-3">


                              <v-col cols="12" sm="5" md="5">
                                  <v-select
                                      v-model="editedItem.id_conductor"
                                      :items="ConductoresArray"
                                      prepend-icon="person_outline"
                                      label="Conductor"
                                  ></v-select>
                            </v-col>


                            
                  <v-col cols="12" sm="5" md="5">
                   

                     <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="310px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="editedItem.expiracion_circulacion"
                            label="Fecha expiracion"
                            prepend-icon="today"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editedItem.expiracion_circulacion"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>



                   <v-col cols="12" sm="5" md="5">
                                  <v-select
                                      v-model="editedItem.id_subflota_veh"
                                      :items="SubFlotasArray"
                                      prepend-icon="person_outline"
                                      label="SubFlota"
                                  ></v-select>
                            </v-col>


                </v-row>




           </v-container>
      
       
        </v-card-text>

        <v-card-actions>
         <v-btn color="primary" dark @click="CambiarPiloto()" class="mx-2">Guardar</v-btn>
          <v-btn outlined color="blue darken-1" @click="dialog=false">cancelar</v-btn>

</v-card-actions>

   </v-card>
</v-dialog>


<v-dialog v-model="dialogOdometro" width="390px">
   <v-card>

     <v-toolbar dark color="fondo1">
       <v-toolbar-title>Odometro activo:{{detalle.unidad}}</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-icon dark @click="dialogOdometro=false">close</v-icon>
     </v-toolbar>

        <v-card-text style="height:150px;" class="text--center">
       
        <v-row class="mt-5">
      
         
          <v-col cols="12" sm="12" md="12">

            <span class="mt-5">Tipo odometro activo:<br><br></span>



                    <input type="radio" value="Hardware" v-model="detalle.tipo_odo_activo"> Hardware
                    <input type="radio" value="Software" v-model="detalle.tipo_odo_activo"> Software
 
            </v-col>
        </v-row>
       
    
        </v-card-text>

        <v-card-actions>
         <v-btn color="primary" dark @click="EstablecerTipoOdo()" class="mx-2">Guardar</v-btn>
          <v-btn outlined color="indigo" @click="dialogOdometro=false">cancelar</v-btn>

</v-card-actions>

   </v-card>
</v-dialog>


<v-dialog v-model="dialogMantto" width="610px">
   <v-card>

     <v-toolbar dark color="fondo1">
       <v-toolbar-title>Datos de Mantenimientos</v-toolbar-title>
       <v-spacer></v-spacer>
       <v-icon dark @click="dialogMantto=false">close</v-icon>
     </v-toolbar>

        <v-card-text class="text--center">
       
        <v-row class="mt-1">
      
        
                  <v-col  cols="12" sm="4" md="5">
                  

                    <v-select
                            v-model="itemMantto.id_tipo_mant"
                            :items="TipoArray"
                            label="Tipo mantenimiento"
                            :rules="[rules.required]"
                            :error-messages="errorMessages"
                        ></v-select>

                  </v-col>


                   <v-col  cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-select
                                v-model="itemMantto.id_taller_mant"
                                :items="itemsTalleres"
                                label="Taller"
                                :rules="[rules.required]"
                                :error-messages="errorMessages"
                            ></v-select>

                     </v-col>


                       <v-col cols="12" sm="4" md="4">
                   

                     <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="itemMantto.fecha_mant"
                            label="Fecha Inicio"
                            prepend-icon="today"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="itemMantto.fecha_mant"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>


                   <v-col cols="12" sm="4" md="4">
                   

                     <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="itemMantto.fecha_fin"
                            label="Fecha Fin"
                            prepend-icon="today"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="itemMantto.fecha_fin"
                            no-title
                            @input="menu2 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>


                    <v-col  cols="12" sm="6" md="3">
                        <v-text-field v-model="itemMantto.costo_mant" prepend-icon="paid" label="costo"></v-text-field>
                      </v-col>

                      <v-col  cols="12" sm="11" md="11">
                        <v-text-field v-model="itemMantto.observacion_mant" prepend-icon="description" label="Observacion"></v-text-field>
                      </v-col>



                         <v-col  cols="12" xs="12" sm="12" md="12" lg="12">

                      <v-list shaped style="height:200px;overflow-y:auto;width:80%;">
                        
                              <v-list-item-group multiple>
                                <template v-for="(item, i) in ItemArray">
                            
                            
                                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                                  <v-list-item v-else :key="item.value" :value="item.value" active-class="deep-purple--text text--accent-4">
                              
                              
                                    <template v-slot:default="{ active }">
                                      <v-list-item-content>
                                        <v-list-item-title v-text="item.text"></v-list-item-title>
                                      </v-list-item-content>

                                      <v-list-item-action>
                                        <v-checkbox
                                          :id="item.value"
                                          :input-value="active"
                                          v-model="ChekedItems"
                                          :value="item.value"
                                          color="deep-purple accent-4"
                                        ></v-checkbox>
                                      </v-list-item-action>
                                    </template>
                                  </v-list-item>
                              
                                </template>
                              </v-list-item-group>
                              <span v-if="ItemArray.length < 1"> no hay items asociados</span>
                              
                            
                            </v-list>

                    </v-col>







        </v-row>
    
        </v-card-text>

        <v-card-actions>
         <v-btn color="primary" dark @click="GuardarMantto()" class="mx-2">Guardar</v-btn>
          <v-btn outlined color="indigo" @click="dialogMantto=false">cancelar</v-btn>

</v-card-actions>

   </v-card>
</v-dialog>


  </v-card>
</template>

<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data () {
      return {
        ArrayflotaGlobal:[],
        Arrayflota:[],
        PaisesArray:[],
        search: '',
        headers: [
          { text: 'Icono', value: 'icono' },
          { text: 'IdAvl', value: 'id_avl' },
          { text: 'nombre', value: 'nombre' },
          { text: 'placa', value: 'placa' },
          { text: 'Flota', value: 'flota' },
          { text: 'SubFlota', value: 'id_subflota_veh.nombre'},

          { text: 'Conductor', value: 'id_conductor'},
          { text:'Expiracion circulacion', value:'expiracion_circulacion'},

          { text: 'OdometroHw', value: 'odometro_hw' },
          { text: 'OdometroSw', value: 'odometro_sw' },

           { text: 'Rendimiento', value: 'rendimiento'},
        

        ],
        dialog:false,
        editedItem:{},
        detalle:{},

        dialogOdometro:false,
        id_pais_activo:'',

        ConductoresArray:[],

        id_estado:'',
        EstadoArray:[{text:'Todos', value:'Todos'},{text:'Activo', value:'Activo'},{text:'Taller', value:'Taller'}],
     
        dialogMantto:false,
        ArregloMantenimientos:[],
     
        itemMantto:{
                  'id_tipo_mant':'',
                  'id_taller_mant':'',
                  'items_realizados':'',
                  'observacion_mant':'',
                  'fecha_mant':'',
                  'fecha_fin':'',
                   'costo_mant':0
        },

      TipoArray:[],
      Tipos:[],
      ArrayTalleres:[],
      itemsTalleres:[],
      ArrayItemMantenimento:[],

      menu1:false,
      menu2:false,
      date: new Date().toISOString().substr(0, 10),

       ItemArray:[],
       ChekedItems:[],

       errorMessages:'',
       rules: {
          numerico:(value)=>value > 0 || "valor incorrecto",
          min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
          required: (value) => !!value || "campo Requerido.",
          email: (value) => {
                              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return pattern.test(value) || "Invalid e-mail.";
                           },
       
       },

       arraySubflotas:[],
       arrayItemSubflotas:[],
       id_subflota_ver:'',

       SubFlotasArray:[], //campo dentro de edicion de camion

      
      




     }
    },
    created () {
      this.listarPaises();
      this.listarItems();
      this.id_estado=this.EstadoArray[0].value;
      
     
    },
    methods:{

       ...mapMutations(['mostrarLoading','ocultarLoading']),


         listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listar();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


           },



         listar(){

                 this.Arrayflota=[];
                 this.ArrayflotaGlobal=[];
               

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`vehiculos/${this.id_pais_activo}`,
                    config
                    ).then(function(response){
                        console.log(response);
                       
                        me.ArrayflotaGlobal=response.data;

                        me.ArrayflotaGlobal.map(function(x){
                          if((x.estado==me.id_estado)||(me.id_estado=='Todos')){
                               me.Arrayflota.push(x);
                          }
                          
                        });

                        me.ocultarLoading();
                        me.listarConductores();
                        me.listarMantto();
                        me.listarTipos();
                        me.listarTalleres();

                        me.listarSubFlotas();

                        
                    }).catch(function(error){ console.log(error); });

          },

          listarSubFlotas(){

            this.arraySubflotas=[];
            this.arrayItemSubflotas=[];
            this.SubFlotasArray=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                 let me=this;


                  axios.get(`subflotas/${this.id_pais_activo}`,
                    config
                    ).then(function(response){
                      
                      
                      //  console.log(response.data);
                        me.arraySubflotas=response.data;

                        me.arrayItemSubflotas.push({text:'Todas', value:0});

                        me.arraySubflotas.map(function(x){
                            me.arrayItemSubflotas.push({text:x.nombre, value:x._id});
                            me.SubFlotasArray.push({text:x.nombre, value:x._id});
                        });

                       
                        me.id_subflota_ver=me.arrayItemSubflotas[0].value;


                   }).catch(function(error){ console.log(error); });

          },

          
      listarMantto(){

              this.ArregloMantenimientos=[];

               this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;

              let fecha_hoy=this.CalcularFechaHoy();
              axios.post('mantenimientos-habilitados',
                      {   
                      'fecha':fecha_hoy,
                      'id_pais_mant':this.id_pais_activo
                    },
                  config
                  ).then(function(response){
                      console.log(response);
                      me.ArregloMantenimientos=response.data;
                   
                     me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

        },

      listarTipos(){

              this.TipoArray=[];
              this.Tipos=[];

              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`tipos/${this.id_pais_activo}`,
                  config
                  ).then(function(response){
                      console.log(response);

                      me.Tipos=response.data;

                            me.Tipos.map(function(x){
                                me.TipoArray.push({'text':x.nombre_tipo, 'value':x._id});
                            });

                            
    
                     me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });
      },

  

      listarTalleres(){
            this.ArrayTalleres=[];
            this.itemsTalleres=[];

              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`talleres/${this.id_pais_activo}`,
                config
                  ).then(function(response){
                      console.log(response);
                      me.ArrayTalleres=response.data;

                      me.ArrayTalleres.map(function(x){
                        me.itemsTalleres.push({text:x.nombre_taller, value:x._id});
                      });
                   
                   me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

      },

      listarItems(){
         this.ArrayItemMantenimento=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`items`,  config
                    ).then(function(response){
                      
                        me.ArrayItemMantenimento=response.data;  
                        me.ArrayItemMantenimento.map(function(x){
                          me.ItemArray.push({text:x.nombre_item, value:x._id});

                        });

                        me.ocultarLoading();

                          console.log(me.ArrayItemMantenimento);
                    }).catch(function(error){ console.log(error); });
      },

        AbrirDetalleMantto(item){
          
          
          let me=this;
          me.itemMantto={};

          this.ArregloMantenimientos.map(function(x){

                  if(x.id_vehiculo_mant._id==item._id){


                       let texto='';

                          if((x.fecha_fin!='')&&(x.fecha_fin!=null)){
                            texto=new Date(x.fecha_fin).toISOString().substr(0, 10);
                          }


                     

                            me.ChekedItems=[];
                        for(let obvjeto of  x.items_realizados){
                          // console.log('iteramos:'+obvjeto.ref_item);
                              me.ChekedItems.push(obvjeto.ref_item);
                        }
                        

                        me.itemMantto={
                            '_id':x._id,
                            'id_vehiculo_mant':x.id_vehiculo_mant._id,
                            'id_tipo_mant':x.id_tipo_mant._id,
                            'id_taller_mant':x.id_taller_mant._id,
                            'items_realizados':x.items_realizados,
                            'observacion_mant':x.observacion_mant,
                            'fecha_mant':new Date(x.fecha_mant).toISOString().substr(0, 10),
                            'fecha_fin':texto,
                            'costo_mant':x.costo_mant
                        };

                    }//fin si es mismo vehiculo

          });


              
            

           this.dialogMantto=true;
         

        },



        GuardarMantto(){


            if((this.itemMantto.id_taller_mant!='')&&(this.itemMantto.fecha_mant!='')&&(this.itemMantto.costo_mant!='')&&(this.ChekedItems.length > 0)
             &&(this.itemMantto.id_tipo_mant!='')){



                  let fecha_fin='';

                  let insertar=false;

                  console.log('fecha fin:'+this.itemMantto.fecha_fin);

                  if(this.itemMantto.fecha_fin!=''){
                           
                            if((new Date(this.itemMantto.fecha_mant+"T00:00:00.000-06:00") < new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00"))
                            &&(new Date(this.itemMantto.fecha_fin+"T00:00:00.000-06:00") < new Date(this.CalcularFechaHoy()+"T00:00:00.000-06:00"))){
                              this.ActualizarEstadoVehiculo( this.itemMantto.id_vehiculo_mant,'Activo');
                            }

                             fecha_fin=this.itemMantto.fecha_fin+"T00:00:00.000-06:00";


                         insertar=true;
                  }else{

                    if((this.itemMantto.fecha_fin==null)||(this.itemMantto.fecha_fin=='')){

                        if (confirm('mantto no tiene fecha final, vehiculo apartir de hoy pasara al estado de taller?')) {
                          // Save it!
                           this.ActualizarEstadoVehiculo( this.itemMantto.id_vehiculo_mant,'Taller');
                           insertar=true;
                        } else {
                          // Do nothing!
                            console.log('nada que hacer');
                        }

                       
                    }

                  }//fin si no hay fecha de fin
             


                  console.log('items:'+this.ChekedItems);
                    let separa=this.ChekedItems;
                    separa=separa.toString();
                    separa=separa.split(",");

                    let ArrayEnviarItems=[];

                  for(let i=0; i < separa.length; i++){
                    console.log('datos:ref_item:'+separa[i]);
                    ArrayEnviarItems.push({ref_item:separa[i]});
                  }


              if(insertar==true){

                    let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'actualizando'});
                    let id=this.itemMantto._id;

                    axios.put(`mantenimientos/${id}`,
                      { 
                            'id_pais_mant': this.id_pais_activo,
                            'id_vehiculo_mant': this.itemMantto.id_vehiculo_mant,
                            'id_tipo_mant': this.itemMantto.id_tipo_mant,
                            'id_taller_mant': this.itemMantto.id_taller_mant,
                            'items_realizados':ArrayEnviarItems,
                            'status':2,
                            'observacion_mant': this.itemMantto.observacion_mant,
                            'fecha_mant': this.itemMantto.fecha_mant+"T00:00:00.000-06:00",
                            'fecha_fin':fecha_fin,
                            'costo_mant':this.itemMantto.costo_mant

                       },
                       config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.dialogMantto=false;
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

            }///fin de guardar 

             

        }else{
          alert('faltan campos');
        }



        },


         ActualizarEstadoVehiculo(_id, estado){   //cambiar a activo, salio de taller

          let config={headers:{token:this.token}};

               this.mostrarLoading({titulo:'Accediendo a datos'});

               console.log('actualizamos el estado a taller');

                    axios.put(`vehiculo/${_id}`,
                      {'estado':estado},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });


    },



          FiltrarVehiculos(){

            let me=this;
            me.Arrayflota=[];

              me.ArrayflotaGlobal.map(function(x){
                          if((x.estado==me.id_estado)||(me.id_estado=='Todos')){

                            if(me.id_subflota_ver==0){
                                me.Arrayflota.push(x);
                            }else{
                              if((x.id_subflota_veh)&&(x.id_subflota_veh!=null)&&(me.id_subflota_ver==x.id_subflota_veh._id)){
                                 me.Arrayflota.push(x);
                              }
                            }
                              
                          }
                        });


          },

         listarConductores () {

          this.ConductoresArray=[];

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'accediendo a datos'});
          let me=this;

          axios.get(`conductores/${this.id_pais_activo}`, config
          ).then(function(response){
              console.log(response);
              let arreglo=response.data;

               arreglo.map(function(x){
                      me.ConductoresArray.push({text:x.nombre_conductor, value:x._id});  
                 });

             me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

         },
 

          ActualizarGrid(){

             let config={headers:{ token:this.token}};
             let me=this;
             me.mostrarLoading({titulo:'actualizando con AVL'});
            axios.post('ActualizarAVL',{ '_id':this.id_pais_activo,
                           
                          },
                        config
                      ).then(response=>{
                      
                          console.log(response);
                          me.ocultarLoading();
                          me.listar();

                      }).catch(e=>{ console.log(e) });            
         }, 
      
         CambiarImgUrl(picture){
            picture=picture.substring(0,picture.length - 5);
            if(picture.slice(-1)!='/'){
                    picture=picture.substring(0,picture.length - 1);
                }
                return (picture+'/E.png');
         },

         VerDetalle(item){

           if(item.observacion!=''){
              this.detalle={
                '_id':item._id,
                'unidad':item.nombre+' / '+item.placa, 
                'observacion':item.observacion
                };

                this.dialog=true;
           }
          

         },


        ViewCambiarpiloto(item){

                  this.editedItem._id=item._id;
                   this.editedItem.id_conductor=item.id_conductor._id;
                  this.editedItem.nombre=item.nombre;
                  this.editedItem.placa=item.placa;

                  if((item.expiracion_circulacion)&&(item.expiracion_circulacion!='')){

                    let texto_fecha=item.expiracion_circulacion
                    let string_fecha = texto_fecha.split("T");
                    this.editedItem.expiracion_circulacion=string_fecha[0];
                  }
                

                  if(item.id_subflota_veh && item.id_subflota_veh._id){
                          this.editedItem.id_subflota_veh=item.id_subflota_veh._id;
                  }
                 
    
                 this.dialog=true;
        },
        CambiarPiloto(){

          this.mostrarLoading({titulo:'Accediendo a datos'});
          let config={headers:{token:this.token}};
          let body_update={'id_conductor':this.editedItem.id_conductor};
          if(this.editedItem.expiracion_circulacion!='' && this.editedItem.expiracion_circulacion!=undefined){
              body_update.expiracion_circulacion= this.editedItem.expiracion_circulacion+" 00:00:00";                  
          }

          console.log('fecha circulacion:'+this.editedItem.expiracion_circulacion);

          if(this.editedItem.id_subflota_veh && this.editedItem.id_subflota_veh!=''){
            body_update.id_subflota_veh=this.editedItem.id_subflota_veh;
          }


           let id=this.editedItem._id;
             axios.put(`vehiculo/${id}`, body_update,
                                 config
                              ).then(response=>{
                               
                                this.dialog=false;
                               this.ocultarLoading();
                                console.log(response);
                                this.listar();
                              }).catch(e=>{
                                    console.log(e)
                              });

          
        },

         CambiarOdometroActivo(item){


          //console.log(item);
         

            this.detalle={
                '_id':item._id,
                'unidad':item.nombre+' / '+item.placa, 
                'tipo_odo_activo':item.tipo_odo_activo
                };

            this.dialogOdometro=true;
         },

         EstablecerTipoOdo(){

      
               this.mostrarLoading({titulo:'Accediendo a datos'});
                let config={headers:{token:this.token}};
               let id=this.detalle._id;

                    axios.put(`vehiculo/${id}`,
                      {'tipo_odo_activo':this.detalle.tipo_odo_activo},
                      config
                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.dialogOdometro=false;
                         this.listar();
                         this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });

         },

        CalcularFechaHoy(){
          let fecha=new Date();
          let mes=fecha.getMonth()+1;
          if(mes<10)mes='0'+mes;
          let dia=fecha.getDate();
          if(dia<10)dia='0'+dia;
          let textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

          return(textofecha);
        },

        ConvertirFecha(fecha_parametro){

          let texto=new Date(fecha_parametro).toLocaleDateString();

          return(texto);

      },

      evaluarVencimineto(fechaVence){

        let icon='';

  //fecha recibida + 1 mes

    let fecha_hoy=new Date();
    let fecha_mes_sig=new Date();

    if(fecha_hoy.getMonth()>11){
       
         fecha_mes_sig.setMonth(0);
         fecha_mes_sig.setFullYear(fecha_hoy.getFullYear()+1);
        // console.log('mes siguiente, fecha:'+fecha_mes_sig);
    }else{

         fecha_mes_sig.setMonth(fecha_hoy.getMonth()+1);
       //  console.log('mes siguiente, fecha:'+fecha_mes_sig);
    }


    let fecha_recibida=new Date(fechaVence);

      if(fecha_mes_sig < fecha_recibida){
      //  console.log('bien');

           icon='done';

      }else{

     // console.log('alerta falta menos de 30 dias');
      
          icon='error';

      }
 

        return(icon);


}


    }, computed:{

       ...mapState(['usuario', 'token']),


    }
  }
</script>