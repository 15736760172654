<template>
  <v-card>
    <v-toolbar flat>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Parametros Paises - {{ texto_barra }}</v-toolbar-title>

      <v-spacer></v-spacer>
      
      <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon  v-bind="attrs"  v-on="on" dark color="indigo" @click="dialogExit=true"> 
              <v-icon dark>logout</v-icon>
            </v-btn>
          </template>
          <span>Salir de aplicacion</span>
      </v-tooltip>

      <template v-slot:extension>
        <v-tabs v-model="tabs" fixed-tabs>

          <v-tabs-slider></v-tabs-slider>


          <v-tab href="#mobile-tabs-5-0" class="primary--text">
            <v-icon>local_shipping</v-icon>
          </v-tab>

           <v-tab href="#mobile-tabs-5-1" class="primary--text">
            <v-icon>commute</v-icon>
          </v-tab>


          <v-tab href="#mobile-tabs-5-2" class="primary--text">
            <v-icon>person</v-icon>
          </v-tab>

          <v-tab href="#mobile-tabs-5-3" class="primary--text">
            <v-icon>today</v-icon>
          </v-tab>


           <v-tab href="#mobile-tabs-5-4" class="primary--text">
            <v-icon>layers</v-icon>
          </v-tab>




          <v-tab v-show="rol_actual=='ADMIN'"  href="#mobile-tabs-5-5" class="primary--text">
            <v-icon>flag</v-icon>
          </v-tab>


          <v-tab v-show="rol_actual=='ADMIN'"  href="#mobile-tabs-5-6" class="primary--text">
            <v-icon>account_circle</v-icon>
          </v-tab>

          <v-tab v-show="rol_actual=='ADMIN'"  href="#mobile-tabs-5-7" class="primary--text">
            <v-icon>car_crash</v-icon>
          </v-tab>

     

         

        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs">


       <v-tab-item :value="'mobile-tabs-5-0'">
        <v-card flat>
          <v-card-text>
             <Vehiculos />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item :value="'mobile-tabs-5-1'">
        <v-card flat>
          <v-card-text>
             <Flotas />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item :value="'mobile-tabs-5-2'">
        <v-card flat>
          <v-card-text>
             <Conductores />
          </v-card-text>
        </v-card>
      </v-tab-item>


      <v-tab-item :value="'mobile-tabs-5-3'">
        <v-card flat>
          <v-card-text>
             <Tipojornadas />
          </v-card-text>
        </v-card>
      </v-tab-item>



      


      <v-tab-item :value="'mobile-tabs-5-4'">
        <v-card flat>
          <v-card-text>
             <Geocercas />
          </v-card-text>
        </v-card>
      </v-tab-item>



      <v-tab-item :value="'mobile-tabs-5-5'">
        <v-card flat>
          <v-card-text>
             <Paises />
          </v-card-text>
        </v-card>
      </v-tab-item>


      <v-tab-item :value="'mobile-tabs-5-6'">
        <v-card flat>
          <v-card-text>
             <Usuarios />
          </v-card-text>
        </v-card>
      </v-tab-item>


      <v-tab-item :value="'mobile-tabs-5-7'">
        <v-card flat>
          <v-card-text>
             <Tercerizados />
          </v-card-text>
        </v-card>
      </v-tab-item>



      
    </v-tabs-items>


    <v-dialog v-model="dialogExit"  hide-overlay
                     persistent
                     width="250">
                        <v-card>
                          <v-card-title>Salir de aplicacion</v-card-title>
                          <v-card-text>Deseas salir?</v-card-text>
                          <v-card-actions class="justify-space-around">
                            <v-btn @click="dialogExit=false" text>Cancelar</v-btn>
                          <v-btn @click="cerrar()" dark color="indigo">Salir</v-btn></v-card-actions>
                        </v-card>
                </v-dialog>


  </v-card>
</template>

<script>

import { mapActions, mapState } from "vuex";

import Paises from '../configuraciones/Pais.vue';
import Usuarios from '../configuraciones/Usuario.vue';
import Vehiculos from '../configuraciones/Vehiculo.vue';
import Conductores from '../configuraciones/Conductor.vue';
import Flotas from '../configuraciones/Flotas.vue';

import Tipojornadas from "./TipoJornadas.vue";

import Geocercas from '../configuraciones/Geocercas.vue';

import Tercerizados from '../configuraciones/Tercerizados.vue'

  export default {
      components:{
          Paises, Usuarios, Geocercas, Vehiculos, Conductores, Flotas, Tipojornadas, Tercerizados
         },
    data () {
      return {
         rol_actual:'',
         dialogExit:false,
         tabs: null,
         texto_barra: 'Vehiculos',
      
      }
    },
     computed:{
        ...mapState(['empresaid', 'token','usuario']),


    },
    watch:{

      tabs(val){
        if(val=='mobile-tabs-5-0'){
          this.texto_barra='Vehiculos';
        }
        if(val=='mobile-tabs-5-1'){
          this.texto_barra='Flotas';
        }
        if(val=='mobile-tabs-5-2'){
           this.texto_barra='Conductores';
        }
        if(val=='mobile-tabs-5-3'){
           this.texto_barra='Jornadas';
        }
        if(val=='mobile-tabs-5-4'){
           this.texto_barra='Geocercas';
        }
        if(val=='mobile-tabs-5-5'){
          this.texto_barra='Datos';
        } 
        if(val=='mobile-tabs-5-6'){
          this.texto_barra='Usuarios';
        }
       
      }

    },

     created () {

     this.rol_actual=this.usuario.rol;

    },

    methods:{

        ...mapActions(['salir']),

         cerrar(){
              this.dialogExit=false;
              localStorage.removeItem('usuario');
              localStorage.removeItem('token');
              
              this.salir();
            },



    }
  }
</script>