<template>

<v-card>
    <v-card-title>
       <span> Datos sync por pais</span>

        <v-divider  class="mx-4" inset vertical></v-divider>


           <v-flex   xs5 sm4 md3 lg3>
          <v-text-field  justify-center
                                v-model="search"
                                append-icon="search"
                                label="Busqueda"
                                single-line
                                hide-details
                              ></v-text-field> 

          </v-flex>

       <v-spacer></v-spacer>

         <v-btn color="primary" dark @click="dialog=true">
              <v-icon dark>add</v-icon>
            </v-btn>

        <v-btn color="success" class="mx-2" @click="listar()"><v-icon>autorenew</v-icon></v-btn>



    </v-card-title>

  <v-data-table
    :headers="headers"
    :search="search"
    :items="ArrayPaises"
    sort-by="creado"
    class="elevation-1 mx-2 my-2"
  >
   
    
     


     


    <template  v-slot:[`item.actions`]="{ item }">
      <v-icon color="green" small class="mr-2" @click="editItem(item)">edit</v-icon>
      <v-icon color="red" small @click="deleteItem(item)">delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>


    <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==1 ? "done" : "label_off" }}
        </v-icon>
  </template>





   <template v-slot:[`item.creado`]="{ item }">
        <span>{{ getDate(item.creado) }}</span>
  </template>

  



  </v-data-table>

 <span>
  <v-icon color="red">warning</v-icon>Datos aqui solo van a ser modificados cuando se cambien credenciales en AVL</span>
   
   
        <v-dialog v-model="dialog" max-width="700px">
         
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
                {{ formTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialog=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
                  <v-col cols="12" sm="5" md="4">

                    <v-autocomplete v-model="editedItem.nombre_pais" :items="itemsAutocompletar" dense filled
                      prepend-icon="flag" label="Nombre pais"></v-autocomplete>
                  </v-col>

                  <v-col cols="5">
                   <v-text-field v-model="editedItem.servidor_avl"  prepend-icon="cloud" label="Servidor AVL"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.usr_avl" prepend-icon="face" label="Usr avl"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.pwd_avl"  prepend-icon="lock" label="Pwd avl"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                   <v-select
                              v-model="editedItem.pais_avl"
                              :items="opcionPais"
                              label="Pais Avl"
                              prepend-icon="flag"
                          ></v-select>
                  </v-col>

                  

                   <v-col cols="12" sm="6" md="4">
                        <span>Estado pais</span><br>
                        <input type="radio" :value="1" v-model="editedItem.activo"> Activo
                        <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
 
                   </v-col>


                  <v-card class="d-inline-flex">

                        <v-flex md4 xs5 lg4 xl4 class="mx-2">
                        <v-select
                                  v-model="editedItem.moneda"
                                  :items="opcionMoneda"
                                  label="Nombre moneda"
                                  prepend-icon="paid"
                              ></v-select>

                        </v-flex>



                    <v-flex md3 xs5 lg3 xl3 class="mx-2">
                      <v-text-field v-model="editedItem.tasa_cambio"  prepend-icon="payments" 
                      label="Tasa cambio"></v-text-field>

                       </v-flex>

                       <v-flex md3 xs5 lg3 xl3 class="mx-2">

                      <v-select 
                                  v-model="editedItem.unidad_medida"
                                  :items="opcionMedida"
                                  label="Unidad medida"
                                  prepend-icon="local_gas_station"
                              ></v-select>
                          </v-flex>
                  
                     

                  </v-card>
                  
                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn  color="primary" dark @click="save"> Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">deseas deshablitar el pais?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

   
   
    </v-card>

</template>


<script>

import { mapState,mapMutations } from "vuex";


import axios from 'axios';

  export default {
    data: () => ({
      search:'',
      dialog: false,
      dialogDelete: false,
       ArrayPaises:[],
      opcionPais:[{text:'NI', value:'ni'},{text:'SV', value:'sv'},{text:'GT', value:'gt'},{text:'PA', value:'pa'}],

       opcionMedida:[{text:'Litros', value:'Litros'},{text:'Galones', value:'Galones'}],

      opcionMoneda:[
                    {text:'Dolar', value:'Dolar'},
                    {text:'Lempira', value:'Lempira'},
                    {text:'Cordoba', value:'Cordoba'},
                    {text:'Quetzal', value:'Quetzal'},
                    {text:'Colones', value:'Colones'},
                    {text:'Balboa', value:'Balboa'}
        
        ],
      headers: [
        { text: 'Nombre pais', value: 'nombre_pais' },
        { text: 'Servidor', value: 'servidor_avl' },
        { text: 'Usr avl', value: 'usr_avl' },
        { text: 'Pwd avl', value: 'pwd_avl' },
        { text: 'Pais avl', value: 'pais_avl' },
        { text: 'Moneda', value: 'moneda'},
        { text: 'Tasa cambio', value:'tasa_cambio'},
        { text: 'Unidad medida', value:'unidad_medida'},
        { text: 'Activo', value: 'activo' },
        { text: 'Creado', value: 'creado' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
              nombre_pais: '',
              servidor_avl:'',
              usr_avl: '',
              pwd_avl: '',
              pais_avl: '',
              activo: 1,
              moneda:'',
              tasa_cambio:1,
              unidad_medida:''
      },
      defaultItem: {
              nombre_pais: '',
              servidor_avl:'',
              usr_avl: '',
              pwd_avl: '',
              pais_avl: '',
              activo: 1,
              moneda:'',
              tasa_cambio:1,
              unidad_medida:''
      },
      
       tipo:{},
       headersVelocidad:[
        { text: 'Nombre', value: 'nombre' },
        { text: 'desde', value: 'desde' },
        { text: 'hasta', value: 'hasta' },
        { text: 'Acciones', value: 'acciones', sortable: false },
       ], 

       id_nuevo_pais:'',

       itemsAutocompletar:['EL Salvador', 'Nicaragua', 'Guatemala', 'Honduras', 'Panama', 'Costa Rica']


    }),

    computed: {

       ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo pais' : 'Edicion pais'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.listar();
    },

    methods: {

        ...mapMutations(['mostrarLoading','ocultarLoading']),
     
     listar () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'accediendo a datos'});
          let me=this;

          axios.get('paises', config
          ).then(function(response){
              console.log(response);
              me.ArrayPaises=response.data;
             me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

      },


      editItem (item) {
        this.editedIndex = this.ArrayPaises.indexOf(item)
        this.editedItem = Object.assign({}, item);

        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayPaises.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
       // this.ArrayPaises.splice(this.editedIndex, 1)
          let id=this.editedItem._id;
          let config={headers:{token:this.token}};

          this.mostrarLoading({titulo:'actualizando'});
          axios.put(`paises/${id}`, 
                                { 
                                    'activo':0
                                },
                                config
                              ).then(response=>{
                               
                                this.closeDelete()
                               // this.ocultarLoading();
                                console.log(response);
                                this.listar();
                                this.ocultarLoading();

                              }).catch(e=>{
                                    console.log(e)
                              });
 
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          //Object.assign(this.ArrayPaises[this.editedIndex], this.editedItem)
            //edicion de campos


                      let id=this.editedItem._id;
                      let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'actualizando'});

                    axios.put(`paises/${id}`, 
                                { 
                                    'nombre_pais':this.editedItem.nombre_pais,
                                    'servidor_avl':this.editedItem.servidor_avl,
                                    'usr_avl':this.editedItem.usr_avl,
                                    'pwd_avl':this.editedItem.pwd_avl,
                                    'pais_avl':this.editedItem.pais_avl,
                                    'activo':this.editedItem.activo,
                                    'moneda':this.editedItem.moneda,
                                    'tasa_cambio':this.editedItem.tasa_cambio,
                                    'unidad_medida':this.editedItem.unidad_medida
                                    
                                },
                                config
                              ).then(response=>{
                               
                                this.close();
                               this.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                              });

        } else {

            //guardamos 
         // this.ArrayPaises.push(this.editedItem)

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'actualizando'});
          axios.post('nuevo-pais',
                    { 
                      'nombre_pais':this.editedItem.nombre_pais,
                      'servidor_avl':this.editedItem.servidor_avl,
                      'usr_avl':this.editedItem.usr_avl,
                      'pwd_avl':this.editedItem.pwd_avl,
                      'pais_avl':this.editedItem.pais_avl,
                      'activo':this.editedItem.activo,
                      'moneda':this.editedItem.moneda,
                      'tasa_cambio':this.editedItem.tasa_cambio,
                      'unidad_medida':this.editedItem.unidad_medida

                       },
               config
          ).then(response=>{


            this.id_nuevo_pais=response.data._id;
            this.crearconductordefault(this.id_nuevo_pais);
          
            this.close();
            console.log(response);
            this.listar();
           this.ocultarLoading();

          }).catch(e=>{
                console.log(e)
          });



        }//guardar datos
       
      },

  

      crearconductordefault(id_nuevo_pais){

             this.mostrarLoading({titulo:'actualizando'});
             let config={headers:{token:this.token}};
            axios.post('nuevo-conductor',
                    { 
                      'nombre_conductor':'vacio',
                      'no_licencia':'N/A',
                      'expiracion':new Date(),
                      'id_conductor_pais':id_nuevo_pais,
                      'activo':1
                       },
               config
          ).then(response=>{
          
           
            console.log(response);
            this.ocultarLoading();
           
          }).catch(e=>{
                console.log(e)
          });



      },




        getDate(evaluar) {
            let date = new Date(evaluar),
            year = date.getFullYear(),
            month = (date.getMonth() + 1).toString(),
            formatedMonth = (month.length === 1) ? ("0" + month) : month,
            day = date.getDate().toString(),
            formatedDay = (day.length === 1) ? ("0" + day) : day,
            hour = date.getHours().toString(),
            formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
            minute = date.getMinutes().toString(),
            formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
            second = date.getSeconds().toString(),
            formatedSecond = (second.length === 1) ? ("0" + second) : second;
          return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
        },






    },
  }
</script>