<template>
  <v-card>
    <v-card-title>
       <span> Conductores</span>

        <v-divider  class="mx-2" inset vertical></v-divider>

         <v-flex md2 lg2 mx-4 pt-4>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
         </v-flex>


         <v-flex  class="mx-2"  xs5 sm4 md5 lg4>
          <v-text-field  justify-center
                                v-model="search"
                                append-icon="search"
                                label="Busqueda"
                                single-line
                                hide-details
                              ></v-text-field> 

          </v-flex>


             <v-spacer></v-spacer>

           <v-btn color="primary"  @click="dialog=true">
              <v-icon>add</v-icon>
            </v-btn>

        <v-btn color="success" class="mx-2" @click="listar()"><v-icon>autorenew</v-icon></v-btn>



    </v-card-title>

  <v-data-table
    :headers="headers"
    :items="ConductoresArray"
    :search="search"
    sort-by="nombre_conductor"
    class="elevation-1 mt-5 mx-1"
  >
  
       
       
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon  color="green" small class="mr-2" @click="editItem(item)">
       edit
      </v-icon>
      <v-icon  color="red" small @click="deleteItem(item)">
        delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>

      <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==true ? "done" : "label_off" }}
        </v-icon>
  </template>

  <template v-slot:[`item.expiracion`]="{ item }">
        <span>{{ getDate(item.expiracion) }}</span>
        <v-icon color="red">{{ evaluarVencimineto(item.expiracion)}}</v-icon>
  </template>

   

 

  </v-data-table>
  <span>
  <v-icon color="red">error</v-icon>licencias menores a 30 dias de vencimiento</span>

  <v-dialog v-model="dialog" max-width="600px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
                {{ formTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialog=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                <v-row>

                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.nombre_conductor"  prepend-icon="person" label="nombre conductor"></v-text-field>
                  </v-col>

                   <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.no_licencia"  prepend-icon="portrait" label="no.licencia"></v-text-field>
                  </v-col>

                    <v-col cols="12" sm="6" md="6">
                                        <v-menu
                                          ref="expiracion"
                                          v-model="expiracion"
                                          :close-on-content-click="false"
                                          :return-value.sync="date"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="290px"
                                        >
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                  v-model="editedItem.expiracion"
                                                  label="Expiracion licencia"
                                                  prepend-icon="event"
                                                  readonly
                                                  v-bind="attrs"
                                                  v-on="on"

                                                  :rules="[rules.required]"
                                                  :error-messages="errorMessages"
                                                ></v-text-field>
                                              </template>
                                              <v-date-picker v-model="editedItem.expiracion"
                                              no-title
                                               @input="expiracion = false"
                                              :rules="[rules.required]"
                                              :error-messages="errorMessages"
                                              
                                               scrollable>
                                        
                                              </v-date-picker>
                                        </v-menu>
                            </v-col>


                            <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.codigo_keypad"  prepend-icon="keyboard" label="Codigo Keypad"></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="6" md="6">
                        <v-select v-model="editedItem.id_jornada_semana" dense 
                        outlined :items="arrayItemJornadas"  label="Jornada semana"></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-select v-model="editedItem.id_jornada_finsemana" dense 
                        outlined :items="arrayItemJornadas"  label="Jornada fin semana"></v-select>
                      </v-col>


                            
                   <v-col cols="12" sm="6" md="6">
                        <span>Estado</span><br>
                        <input type="radio" :value="1" v-model="editedItem.activo"> Activo
                        <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
 
                   </v-col>


                


                 
                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" dark @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">deseas deshabilitar conductor?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

</v-card>
</template>


<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";

  export default {
    data: () => ({
      ConductoresArray:[],
      date: new Date().toISOString().substr(0, 10),
      expiracion:false,

       search:'',
      dialog: false,
      dialogDelete: false,
      headers: [
     
        { text: 'Nombre', value: 'nombre_conductor' },
        { text: 'No.licencia', value: 'no_licencia' },
        { text: 'Expiracion', value: 'expiracion' },
        { text: 'Codigo keypad', value: 'codigo_keypad' },
        { text: 'Jornada semana', value: 'id_jornada_semana.nombre_jornada' },
        { text: 'jornada fin semana', value: 'id_jornada_finsemana.nombre_jornada' },
        { text: 'Activo', value: 'activo' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        nombre_conductor: '',
        no_licencia: '',
        expiracion: new Date().toISOString().substr(0, 10),
        id_conductor_pais: 0,
        id_jornada_semana:'',
        id_jornada_finsemana:'',
        activo: 1,
        codigo_keypad:1,
        creado:new Date()
      },
      defaultItem: {
        nombre_conductor: '',
        no_licencia: '',
        expiracion: new Date().toISOString().substr(0, 10),
        id_conductor_pais: 0,
        id_jornada_semana:'',
        id_jornada_finsemana:'',
        activo: 1,
        codigo_keypad:1,
        creado:new Date()
      },


       errorMessages: '',
      valid: true,
      rules: {
          min3Chars: (value) => value.length >= 3 || "Min. 3 characters",
          required: (value) => !!value || "Campo Requerido.",
          email: (value) => {
                              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              return pattern.test(value) || "Invalid e-mail.";
                            },
          },

       id_pais_activo:'',
       PaisesArray:[],

       arrayItemJornadas:[]


    }),

    computed: {

       ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo conductor' : 'Edicion conductor'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.listarJornadas();
      this.listarPaises();
    },

    methods: {

       ...mapMutations(['mostrarLoading','ocultarLoading']),

       listarJornadas(){

          let config={headers:{token:this.token}};
          this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         this.arrayItemJornadas=[];

         axios.get(`tipojornadas`, config).then(function(response){

                        //  console.log(response.data);
                        let respuestas=response.data;

                        respuestas.map(function(x){
                            me.arrayItemJornadas.push({text:x.nombre_jornada, value:x._id});
                        });



                       me.ocultarLoading();
                            
                    }).catch(function(error){
                    console.log(error);
                    });


       },


      listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listar();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


           },
     

      listar () {

          let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'accediendo a datos'});
          let me=this;

          axios.get(`conductores/${this.id_pais_activo}`, config
          ).then(function(response){
              console.log(response);
              me.ConductoresArray=response.data;
              me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

      },



      editItem (item) {


            this.editedIndex = this.ConductoresArray.indexOf(item)
            this.editedItem = Object.assign({}, item);
            this.editedItem.expiracion=new Date(item.expiracion).toISOString().substr(0, 10);
        

          if(this.editedItem.nombre_conductor=='vacio'){
             alert('no se puede debe cambiar datos de conductor por defecto');
          }else{
            this.dialog = true;
          }


          if(item.id_jornada_semana && item.id_jornada_semana._id){
                          this.editedItem.id_jornada_semana=item.id_jornada_semana._id;
           }

          if(item.id_jornada_finsemana && item.id_jornada_finsemana._id){
                  this.editedItem.id_jornada_finsemana=item.id_jornada_finsemana._id;
          }

      },

      deleteItem (item) {
        this.editedIndex = this.ConductoresArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true;
      },

      deleteItemConfirm () {
       // this.ConductoresArray.splice(this.editedIndex, 1)
      
      if(this.editedItem.nombre_conductor=='vacio'){
          alert('no se puede deshbibiliar conductor por defecto');
      }else{

            let id=this.editedItem._id;
            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'accediendo a datos'});

             axios.put(`conductor/${id}`, 
                                {'activo':0},
                                config
                              ).then(response=>{
                               
                                 this.closeDelete();
                                 this.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                              });

      }

         


      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
       
              let id=this.editedItem._id;
               this.mostrarLoading({titulo:'accediendo a datos'});
               let config={headers:{token:this.token}};


               let body_update= { 
                                  'nombre_conductor':this.editedItem.nombre_conductor,
                                  'no_licencia':this.editedItem.no_licencia,
                                  'expiracion':new Date(this.editedItem.expiracion+" 00:00:00"),
                                  'codigo_keypad':this.editedItem.codigo_keypad,
                                  'activo':this.editedItem.activo 
                                };

           if(this.editedItem.id_jornada_semana && this.editedItem.id_jornada_semana!=''){
            body_update.id_jornada_semana=this.editedItem.id_jornada_semana;
          }

          if(this.editedItem.id_jornada_finsemana && this.editedItem.id_jornada_finsemana!=''){
            body_update.id_jornada_finsemana=this.editedItem.id_jornada_finsemana;
          }

          console.log(body_update);



             axios.put(`conductor/${id}`,body_update,
                                config
                              ).then(response=>{
                               
                                this.close();
                               this.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                              });



        } else {
            
         //Guardamos datos de conductores

            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'accediendo a datos'});

            axios.post('nuevo-conductor',
                    { 
                      'nombre_conductor':this.editedItem.nombre_conductor,
                      'no_licencia':this.editedItem.no_licencia,
                      'expiracion':new Date(this.editedItem.expiracion+" 00:00:00"),
                      'codigo_keypad':this.editedItem.codigo_keypad,
                      'id_conductor_pais':this.id_pais_activo,
                      'id_jornada_semana':this.editedItem.id_jornada_semana,
                      'id_jornada_finsemana':this.editedItem.id_jornada_finsemana,
                      'activo':this.editedItem.activo,
                   

                       },
              config
          ).then(response=>{


         
          
            this.close();
            console.log(response);
            this.listar();
            this.ocultarLoading();

          }).catch(e=>{
                console.log(e)
          });



        }
        
      },



      getDate(evaluar) {
          let date = new Date(evaluar),
          year = date.getFullYear(),
          month = (date.getMonth() + 1).toString(),
          formatedMonth = (month.length === 1) ? ("0" + month) : month,
          day = date.getDate().toString(),
          formatedDay = (day.length === 1) ? ("0" + day) : day;
         //  hour = date.getHours().toString(),
         // formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
         // minute = date.getMinutes().toString(),
         // formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
         // second = date.getSeconds().toString(),
         //  formatedSecond = (second.length === 1) ? ("0" + second) : second;
        return formatedDay + "-" + formatedMonth + "-" + year;
  },


evaluarVencimineto(fechaVence){

  let icon='';

  //fecha recibida + 1 mes

    let fecha_hoy=new Date();
    let fecha_mes_sig=new Date();

    if(fecha_hoy.getMonth()>11){
       
         fecha_mes_sig.setMonth(0);
         fecha_mes_sig.setFullYear(fecha_hoy.getFullYear()+1);
        // console.log('mes siguiente, fecha:'+fecha_mes_sig);
    }else{

         fecha_mes_sig.setMonth(fecha_hoy.getMonth()+1);
       //  console.log('mes siguiente, fecha:'+fecha_mes_sig);
    }


    let fecha_recibida=new Date(fechaVence);

      if(fecha_mes_sig < fecha_recibida){
      //  console.log('bien');

           icon='done';

      }else{

     // console.log('alerta falta menos de 30 dias');
      
          icon='error';

      }
 

        return(icon);


}



    },
  }
</script>