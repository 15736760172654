<template>

    <v-card>
        <v-card-title>
           <span> Tipo Jornadas</span>

           <v-spacer></v-spacer>

           <v-btn color="accent" dark @click="dialog=true">
              <v-icon dark>add</v-icon>
              
            </v-btn>

             <v-btn color="success" class="mx-2" @click="listar()"><v-icon>autorenew</v-icon></v-btn>



        </v-card-title>

        <v-card-text>

            <v-data-table
                :headers="headers"
                :items="arrayJornadas"
                :search="search"
                sort-by="nombre_jornada"
                class="elevation-1 mt-5 mx-1"
            >

       

     
    <template v-slot:[`item.actions`]="{ item }">
    
      <v-icon small class="mr-2" @click="editItem(item)" color="green" >edit</v-icon>
      <v-icon small @click="deleteItem(item)" color="red" >delete</v-icon>
    
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>


     <template v-slot:[`item.creado`]="{ item }">
        <span>{{ getDate(item.creado) }}</span>
  </template>

   <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==true ? "done" : "label_off" }}
        </v-icon>
  </template>

  </v-data-table>


  
 <v-dialog v-model="dialog" max-width="600px">

<v-card>


     <v-toolbar color="fondo1">
    <v-toolbar-title class="white--text">
      {{ formTitle }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-icon dark @click="dialog=false">close</v-icon>
  </v-toolbar>

  

  <v-card-text>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field v-model="editedItem.nombre_jornada" label="Nombre Jornada"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="editedItem.hora_desde" label="Hora desde"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="editedItem.hora_hasta" label="Hora hasta"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="editedItem.dias" label="Dias"></v-text-field>
        </v-col>


           <v-col cols="12" sm="6" md="6">
              <span>Estado</span><br>
              <input type="radio" :value="1" v-model="editedItem.activo"> activo
              <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo

         </v-col>



        
      </v-row>
    </v-container>
  </v-card-text>

  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
    <v-btn color="primary" dark @click="save">Guardar</v-btn>
  </v-card-actions>


</v-card>
</v-dialog>

<v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Deseas eliminar jornada?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>



 </v-card-text>




    </v-card>



    </template>


<script>




import axios from 'axios';
import { mapMutations, mapState } from "vuex";



  export default {
    data: () => ({
         dialog: false,
         dialogDelete: false,
         arrayJornadas: [],
         search:'',

         headers:[
                    { text: 'Nombre Jornada', value: 'nombre_jornada' },
                    { text: 'Desde', value: 'hora_desde' },
                    { text: 'Hasta', value: 'hora_hasta' },
                    { text: 'Dias', value: 'dias' },
                    { text: 'Activo', value: 'activo' },
                    { text: 'Actions', value: 'actions', sortable: false },
                  ],

        editedIndex: -1,
        editedItem: {
            nombre_jornada: '',
            hora_desde: '08:00:00',
            hora_hasta:'17:00:00',
            dias:1,
            activo: 1,
            creado:  new Date(),
        
        },
        defaultItem: {
            nombre_jornada: '',
            hora_desde: '08:00:00',
            hora_hasta:'17:00:00',
            dias:1,
            activo: 1,
            creado:  new Date(),
        },





    }),

computed: {

 ...mapState(['usuario', 'token']),

  formTitle () {
    return this.editedIndex === -1 ? 'Nueva jornada' : 'Edicion jornada'
  },
},

watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

created () {
  this.listar();
},

methods: {

         ...mapMutations(['mostrarLoading','ocultarLoading']),

            listar () {

                        this.arrayJornadas=[];

                        let config={headers:{token:this.token}};
                        this.mostrarLoading({titulo:'Accediendo a datos'});

                        let me=this;

                        axios.get('tipojornadas',  config
                        ).then(function(response){
                            console.log(response);
                            me.arrayJornadas=response.data;

                            me.ocultarLoading();
                            
                        }).catch(function(error){
                        console.log(error);
                        });




            }, 
            editItem (item) {
                this.editedIndex = this.arrayJornadas.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },
            deleteItem (item) {
            this.editedIndex = this.arrayJornadas.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {

                let id=this.editedItem._id;
                let config={headers:{token:this.token}};
                this.mostrarLoading({titulo:'accediendo a datos'});
                
                axios.put(`tipojornada/${id}`, 
                                {'activo':0},
                                config
                                ).then(response=>{
                                
                                    this.closeDelete();
                                    this.ocultarLoading();
                                console.log(response);
                                this.listar();

                                }).catch(e=>{
                                    console.log(e)
                                });


        },

            getDate(evaluar) {
                let date = new Date(evaluar),
                year = date.getFullYear(),
                month = (date.getMonth() + 1).toString(),
                formatedMonth = (month.length === 1) ? ("0" + month) : month,
                day = date.getDate().toString(),
                formatedDay = (day.length === 1) ? ("0" + day) : day,
                hour = date.getHours().toString(),
                formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
                minute = date.getMinutes().toString(),
                formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
                second = date.getSeconds().toString(),
                formatedSecond = (second.length === 1) ? ("0" + second) : second;
                return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
            },

            close () {
            this.dialog = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },

        save () {
        if (this.editedIndex > -1) {
    
          //edicion 
                 this.mostrarLoading({titulo:'accediendo a datos'});
                 let id=this.editedItem._id;
                 let config={headers:{token:this.token}};

             axios.put(`tipojornada/${id}`, 
                                { 
                                  'nombre_jornada':this.editedItem.nombre_jornada,
                                  'hora_desde':this.editedItem.hora_desde,
                                  'hora_hasta':this.editedItem.hora_hasta,
                                  'dias':this.editedItem.dias,
                                  'activo':this.editedItem.activo
                                },
                            config
                              ).then(response=>{
                               
                                this.close();
                                this.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                              });




        } else {
       
     
              //Guardamos datos

              this.mostrarLoading({titulo:'accediendo a datos'});
              let config={headers:{token:this.token}};
            axios.post('nuevo-tipojornada',
                    { 
                                 'nombre_jornada':this.editedItem.nombre_jornada,
                                  'hora_desde':this.editedItem.hora_desde,
                                  'hora_hasta':this.editedItem.hora_hasta,
                                  'dias':this.editedItem.dias,
                                  'activo':this.editedItem.activo
                       },
              config
          ).then(response=>{


         
          
            this.close();
            console.log(response);
            this.listar();
             this.ocultarLoading();

          }).catch(e=>{
                console.log(e)
          });






        }
     
      },

        
        
        },
  }
</script>