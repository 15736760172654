<template>

<v-layout class="pa-0">

  <div v-show="ver_flota==true">
    <v-btn small class="ml-5 mt-3" @click="ver_flota=false">
      <v-icon dark>visibility</v-icon>SubFlotas
      </v-btn>
   </div>

            <v-card v-show="ver_flota==true" class="ml-5 pr-0 mr-0">
                <v-card-title>

                    <span>Flotas</span>

                    <v-divider  class="mx-2" inset vertical></v-divider>

                    <v-flex md4 lg3 mx-4 pt-4>
                                    <v-select v-model="id_pais_activo" dense 
                                    outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
                    </v-flex>

                      <v-flex  class="mx-2"  xs5 sm4 md5 lg4>
                      <v-text-field  justify-center
                                            v-model="search"
                                            append-icon="search"
                                            label="Busqueda"
                                            single-line
                                            hide-details
                                          ></v-text-field> 

                      </v-flex>


                   
                        <v-spacer></v-spacer>

                

                  <v-btn dark color="indigo" class="mx-5" @click="ActualizarGrid()"><v-icon>autorenew</v-icon></v-btn>

                </v-card-title>



                    <v-data-table
                    :headers="headers"
                    :items="arrayitemFlota"
                    :search="search"
                    sort-by="calories"
                    class="elevation-1"
                >



                  <template v-slot:[`item.actions`]="{ item }">

                    <v-icon  color="green" small class="mr-2" @click="editItem(item)">
                  edit
                  </v-icon>

                  <v-icon  color="red" small @click="deleteItem(item)">
                    delete
                  </v-icon>

                </template>

                    

                <template v-slot:[`item.clasificacion_velocidad`]="{ item }">

                <div class="text-caption" v-for="elemento in item.clasificacion_velocidad" :key="'indice_'+elemento.nombre" >
                  <span class="text-caption"> {{elemento.nombre }}: {{elemento.desde }} -> {{elemento.hasta }} </span>
                </div>
                
                </template>




                <template v-slot:no-data>
                  <v-btn color="primary" @click="listar()">Reset</v-btn>
                </template>
              </v-data-table>


                    <v-dialog v-model="dialog"  max-width="600px">
                      
                      <v-card>


                        <v-toolbar dark color="fondo1">
                          <v-toolbar-title>
                            <span class="headline">Edicion flota</span>
                          </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-icon @click="dialog=false" dark>close</v-icon>
                        </v-toolbar>




                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="editedItem.nombre_flota" prepend-icon="commute" label="Nombre Flota AVL"></v-text-field>
                              </v-col>

                            

                            

                              <v-col cols="12" sm="6" md="6" class="mt-5">
                                    <v-flex class="d-inline mt-8"><span><strong>Estado:</strong></span></v-flex>
                                    <v-flex class="d-inline mx-2"> <input type="radio" :value="1" v-model="editedItem.activo"> Activo</v-flex>
                                    <v-flex class="d-inline"> <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo</v-flex>
            
                              </v-col>


                                <v-col cols="12" sm="6" md="12">
                                <v-text-field v-model="editedItem.correo_encargado" prepend-icon="email"  label="Correo encargado mantto"></v-text-field>
                              </v-col>


                              <v-col cols="12" sm="6" md="12">
                                <span class="ml-8 mb-1"><strong>Clasificacion velocidades</strong></span>
                              <v-data-table
                                        :headers="headersVelocidad"
                                        :items="editedItem.clasificacion_velocidad"
                                        :items-per-page="5"
                                        class="elevation-1">
                                    
                                        <template  v-slot:[`item.acciones`]="{ item }">
                                        <v-icon small @click="editarVelocidad(item)">edit</v-icon>
                                        </template>
                    
                                    </v-data-table>
                              </v-col>

                              
                            </v-row>



                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="indigo" text @click="close">Cancelar</v-btn>
                          <v-btn color="primary" dark @click="save">Guardar</v-btn>
                        </v-card-actions>


                    


                      </v-card>
                    </v-dialog>


              <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5">deseas deshabilitar flota?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                      <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>


              <v-dialog v-model="dialogEditar" max-width="500px">

                <v-toolbar dark color="fondo1">
                  <v-toolbar-title >Editar Velocidad</v-toolbar-title>
                  <v-spacer></v-spacer>
                    <v-icon @click="dialogEditar=false">close</v-icon>
                </v-toolbar>

                          <v-card>

                            <v-row>
                                      <v-col cols="3">
                                          <v-text-field v-model="velocidad_editar.nombre" prepend-icon="label" label="Nombre" disabled></v-text-field>
                                      </v-col>

                                      <v-col cols="3">
                                          <v-text-field v-model="velocidad_editar.desde" prepend-icon="speed" label="Desde"></v-text-field>
                                      </v-col>

                                      <v-col cols="3">
                                          <v-text-field v-model="velocidad_editar.hasta" prepend-icon="speed" label="Hasta"></v-text-field>
                                      </v-col>

                                      <v-col cols="2">
                                      <v-btn dark color="indigo"  small class="mx-1 mt-3" @click="GuardarVelocidad()">Guardar</v-btn>
                                      </v-col>

                            </v-row>


                          
                          </v-card>


                          </v-dialog>


                     



                
   </v-card>


    <div v-show="ver_flota==false">
    <v-btn small class="ml-5 mt-3" @click="ver_flota=true">
      <v-icon dark>visibility</v-icon>Flotas
      </v-btn>
   </div>

         <v-card v-show="ver_flota==false" class="ml-5">
          <Subflotas/>
          
         </v-card>
</v-layout>




</template>

<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";
import Subflotas from '../configuraciones/Subflotas.vue';

  export default {
    components:{
          Subflotas
        
        },
    data: () => ({
      search:'',
      dialog: false,
      dialogDelete: false,
      arrayitemFlota: [],
      headers: [
        { text: 'Nombre Flota', value: 'nombre_flota' },
        { text: 'clasificacion_velocidad', value: 'clasificacion_velocidad' },
        { text: 'Correo Encargado mantto', value:'correo_encargado'},
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      
      editedIndex: -1,
      editedItem: {
        nombre_flota: '',
        clasificacion_velocidad: [],
        id_pais_fl: '',
        activo:1,
        correo_encargado:'',
        creado: new Date()
      },
      defaultItem: {
        nombre_flota: '',
        clasificacion_velocidad: [],
        id_pais_fl:'',
        activo: 1,
        correo_encargado:'',
        creado: new Date()
      },

       id_pais_activo:'',
       PaisesArray:[],


        headersVelocidad:[
        { text: 'Nombre', value: 'nombre' },
        { text: 'desde', value: 'desde' },
        { text: 'hasta', value: 'hasta' },
        { text: 'Edicion', value: 'acciones', sortable: false },
       ],

       dialogEditar:false,
       velocidad_editar:{},

       ver_flota:true,
    

    }),

    computed: {
        ...mapState(['usuario', 'token']),
    },

    watch: {
      dialog (val) {
        val || this.close()
      },

       dialogDelete (val) {
        val || this.closeDelete()
      }

    },

    created () {
      this.listarPaises();
    },

    methods: {

        ...mapMutations(['mostrarLoading','ocultarLoading']),

    listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listar();
            
              
          }).catch(function(error){
            console.log(error);
          });

         
         },


      listar(){

               this.arrayitemFlota=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`flotas/${this.id_pais_activo}`,
                    config
                    ).then(function(response){
                      
                        me.arrayitemFlota=response.data;

                       // alert(me.arrayitemFlota[0].nombre_flota);
                        me.ocultarLoading();

                          console.log(me.arrayitemFlota);
                    }).catch(function(error){ console.log(error); });

          },



      editItem (item) {
        this.editedIndex = this.arrayitemFlota.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

        deleteItem (item) {
        this.editedIndex = this.arrayitemFlota.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true;
      },

       closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },



        deleteItemConfirm () {
       // this.desserts.splice(this.editedIndex, 1)
      

            let id=this.editedItem._id;
            let config={headers:{token:this.token}};
            this.mostrarLoading({titulo:'accediendo a datos'});

             axios.put(`flotas/${id}`, 
                                {'activo':0},
                                config
                              ).then(response=>{
                               
                                 this.closeDelete();
                                 this.ocultarLoading();
                                console.log(response);
                                this.listar();

                              }).catch(e=>{
                                    console.log(e)
                              });

      

         


      },


    

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

    

      save () {
        if (this.editedIndex > -1) {
         // Object.assign(this.arrayitemFlota[this.editedIndex], this.editedItem)
        //edicion de campos


                   let config={headers:{token:this.token}};
                   this.mostrarLoading({titulo:'actualizando'});
                    let id=this.editedItem._id;

                    axios.put(`flotas/${id}`,
                      { 

                        'clasificacion_velocidad': this.editedItem.clasificacion_velocidad,
                        'activo': this.editedItem.activo,
                        'correo_encargado':this.editedItem.correo_encargado
                       },
                     config

                        ).then(response=>{
                      
                         console.log(response);
                        
                         this.listar();
                         this.ocultarLoading();
                        this.close()

                  }).catch(e=>{
                        console.log(e)
                  });






        } 
      
      },

    ActualizarGrid(){

             let config={headers:{ token:this.token}};
             let me=this;
             me.mostrarLoading({titulo:'actualizando con AVL'});
            axios.post('ActualizarAVL',{ '_id':this.id_pais_activo,
                           
                          },
                        config
                      ).then(response=>{
                      
                          console.log(response);
                          me.ocultarLoading();
                          me.listar();

                      }).catch(e=>{ console.log(e) });            
         }, 


     editarVelocidad(item){

      this.velocidad_editar={
        'nombre':item.nombre,
        'desde':item.desde,
        'hasta':item.hasta
      };
       this.dialogEditar=true;


     },

     GuardarVelocidad(){

         let me=this;

         if((this.velocidad_editar.desde!='')&&(this.velocidad_editar.hasta!='')){

            if(parseFloat(this.velocidad_editar.desde) < parseFloat(this.velocidad_editar.hasta)){

               this.editedItem.clasificacion_velocidad.map(function(x){
                if(x.nombre==me.velocidad_editar.nombre){
                  x.desde=me.velocidad_editar.desde;
                  x.hasta=me.velocidad_editar.hasta;
                }
              });
                
                this.velocidad_editar={};
                this.dialogEditar=false;

            }else{
                alert('campo incorrecto: desde-hasta');
            }
            

        }else{
            alert('faltan campos');
        }


      
      
     }




    },
  }
</script>