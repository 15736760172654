<template align-center>

 <v-card>
    <v-card-title>
      <span>Usuarios</span>

      <v-divider  class="mx-4" inset vertical></v-divider>



        <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" label="Busqueda"
           single-line hide-details></v-text-field> 
        </v-flex>

        <v-spacer></v-spacer>

        <v-btn dark color="indigo" class="mx-5" @click="listar()"><v-icon>autorenew</v-icon></v-btn>

         <v-btn   color="accent" dark  @click="dialog=true">Nuevo</v-btn>


    </v-card-title>


  <v-data-table
    :headers="headers"
    :items="ArrayUsuarios"
    :search="search"
    sort-by="usr"
    class="elevation-1"
  >
   
    

 <template v-slot:[`item.estado`]="{ item }">
        <v-icon>
           {{ item.estado==1 ? "done" : "label_off" }}
        </v-icon>
  </template>


<template v-slot:[`item.id_usr_pais`]="{ item }">
        <span>
           {{ item.id_usr_pais.nombre_pais }}
        </span>
  </template>

  <template v-slot:[`item.creado`]="{ item }">
        <span>{{ FormatearFechaHora(item.creado)}}</span>
                 
     </template>



  <template v-slot:[`item.pwd`]="{ item }">

    <v-icon>vpn_key</v-icon>
        <span>
        {{item.pwd}}
        </span>
  </template>


    <template v-slot:[`item.actions`]="{ item }">
      <v-icon color="green"
        small
        class="mr-2"
        @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon color="red"
        small
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">Reset</v-btn>
    </template>

    
  </v-data-table>



  <v-dialog v-model="dialog" max-width="600px">
          <v-card>
            <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
                {{ formTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialog=false">close</v-icon>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field prepend-icon="person" v-model="editedItem.nombre_usuario" label="nombre_usuario"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="5">
                     <v-select prepend-icon="manage_accounts"
                              v-model="editedItem.rol"
                              :items="rolesArray"
                              label="rol"
                          ></v-select>

                  </v-col>


                  <v-col  v-show="editedItem.rol=='TERCERIZADO'" cols="12" sm="6" md="5">
                     <v-select prepend-icon="commute"
                              v-model="editedItem.id_tercerizado"
                              :items="tercerizadosArray"
                              label="Tercerizado"
                          ></v-select>

                  </v-col>




                  <v-col cols="12" sm="6" md="5">
                    <v-text-field  prepend-icon="person_pin" v-model="editedItem.usr" label="usr"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="5">
                    <v-text-field prepend-icon="lock" v-model="editedItem.pwd" label="pwd"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="5">

                  <v-select prepend-icon="flag"
                              v-model="editedItem.id_usr_pais"
                              :items="ArrayPais"
                              label="Pais"
                          ></v-select>
                  
                  
                  </v-col>


                   <v-col cols="12" sm="6" md="4">

                    <input type="radio" :value="1" v-model="editedItem.estado"> Activo
                    <input type="radio" :value="0" v-model="editedItem.estado"> Inactivo
 
                  </v-col>


                   <v-col cols="12" sm="12" md="12">
                    <v-text-field  prepend-icon="email" v-model="editedItem.email" label="Correo"></v-text-field>
                  </v-col>

               

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>

</v-card>
</template>

<script>


import axios from 'axios';

import { mapState, mapMutations } from "vuex";



  export default {
    data: () => ({
        search:'',
        mensaje:'',
        ArrayUsuarios:[],
        ArrayPais:[],
        rolesArray:[{text:'USER', value:'USER'},{text:'ADMIN', value:'ADMIN'}, {text:'TERCERIZADO', value:'TERCERIZADO'}],
      dialog: false,
      headers: [
        { text: 'Nombre usuario', value: 'nombre_usuario' },
        { text: 'Rol', value: 'rol' },
        { text: 'Usr', value: 'usr' },
        { text: 'Pwd', value: 'pwd' },
        { text: 'Pais', value: 'id_usr_pais' },
        { text: 'Activo', value: 'estado' },
        { text: 'Creado', value: 'creado' },
        { text: 'Email', value: 'email' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        nombre_usuario: '',
        rol: '',
        usr: '',
        pwd: '',
        id_usr_pais: '',
        email:'',
        id_tercerizado:'',
        estado:1
      },
      defaultItem: {
        nombre_usuario: '',
        rol: '',
        usr: '',
        pwd: '',
        id_usr_pais: '',
        email:'',
        id_tercerizado:'',
        estado:1
      },

      tercerizadosArray:[],


    }),

    computed: {

       ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.listar();
      this.listarPaises();
      this.listarTercerizados();
    },

    methods: {

       ...mapMutations(['mostrarLoading','ocultarLoading']),

      listar () {

        let config={headers:{token:this.token}};
        this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;
        axios.get('usuarios', config
          ).then(function(response){
             console.log(response);
             me.ArrayUsuarios=response.data;
           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

        
      },

       listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         let RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             RespuestaPaises=response.data;

             RespuestaPaises.map(function(x){
                  me.ArrayPais.push({text:x.nombre_pais, value:x._id})
                });

             me.ocultarLoading();
              
          }).catch(function(error){
            console.log(error);
          });
      },

      listarTercerizados(){


         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});
         let me=this;

        this.tercerizadosArray=[];

        axios.get('tercerizados-all',config).then(function(response){
            console.log(response);
            let respuesta=response.data;

            respuesta.map(function(x){
                me.tercerizadosArray.push({text:x.nombre_tercerizado, value:x._id})
              });

            me.ocultarLoading();
            
        }).catch(function(error){
          console.log(error);
        });

      },

      editItem (item) {
        this.editedIndex = this.ArrayUsuarios.indexOf(item)
        this.editedItem = Object.assign({}, item)

          //asignamos en selec la opcion que corresponde en edicion
          this.editedItem.id_usr_pais = item.id_usr_pais._id;

          if(this.editedItem.rol=='TERCERIZADO'){
            console.log(item);

            if(item.id_tercerizado){
              this.editedItem.id_tercerizado=item.id_tercerizado._id;
            }
           
          }
     
        this.dialog = true
      },

      deleteItem (item) {
        const index = this.ArrayUsuarios.indexOf(item)

        let r= confirm('Deseas deshabilitar usuario?');

           if(r==true){

                    let config={headers:{token:this.token}};
                   this.mostrarLoading({titulo:'Accediendo a datos'});
             
                    let id=item._id;

                    axios.put(`usuario/${id}`,{ '_id':item._id,'estado':0}, config
                        ).then(response=>{
                      
                        console.log(response);
                        this.ArrayUsuarios.splice(index, 1);
                        this.listar();
                        this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                  });
             
           }

      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {



          if((this.editedItem.email!='')&&(this.editedItem.nombre_usuario!='')&&
                  (this.editedItem.usr!='')&&(this.editedItem.pwd!='')){

                 this.mostrarLoading({titulo:'Accediendo a datos'});

                  //actualizar datos de item
                    let config={headers:{token:this.token}};
             
                    let id=this.editedItem._id;

                    axios.put(`usuario/${id}`,
                      { 
                      '_id':this.editedItem._id,
                      'nombre_usuario':this.editedItem.nombre_usuario,
                      'rol':this.editedItem.rol,
                      'usr':this.editedItem.usr,
                      'pwd':this.editedItem.pwd,
                      'id_usr_pais':this.editedItem.id_usr_pais,
                      'id_tercerizado':this.editedItem.id_tercerizado,
                      'email':this.editedItem.email,
                      'estado':this.editedItem.estado
                       },
                         config

                        ).then(response=>{
                      
                        console.log(response);
                     
                        this.listar();
                        this.ocultarLoading();
                         this.close();

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });

           }else{
              alert('faltan campos que ingresar')
            }




        } else {

              //guardamos datos

              if((this.editedItem.email!='')&&(this.editedItem.nombre_usuario!='')&&
                  (this.editedItem.usr!='')&&(this.editedItem.pwd!='')){


                      console.log('pais:'+this.editedItem.id_usr_pais);


                      let config={headers:{token:this.token}};
                      this.mostrarLoading({titulo:'Accediendo a datos'});

                      console.log('id tercerizado:'+this.editedItem.id_tercerizado)

                  axios.post('nuevo-usuario',
                            { 
                              'nombre_usuario':this.editedItem.nombre_usuario,
                              'rol':this.editedItem.rol,
                              'usr':this.editedItem.usr,
                              'pwd':this.editedItem.pwd,
                              'id_usr_pais':this.editedItem.id_usr_pais,
                              'id_tercerizado':this.editedItem.id_tercerizado,
                              'email':this.editedItem.email,
                              'estado':this.editedItem.estado
                              },
                          config
                  ).then(response=>{
                  
                    this.close();
                    console.log(response);
                    this.listar();
                      this.ocultarLoading();

                  }).catch(e=>{
                        console.log(e)
                      
                  });

            }else{
              alert('faltan campos que ingresar')
            }

        }
      
      },

       FormatearFechaHora(FechaHoraInicial){
                       var pFechaHoraInicial= new Date(FechaHoraInicial).toISOString().substring(0,10);
                       var textoHora=FechaHoraInicial.split("T")[1];
                        textoHora= textoHora.substring(0,textoHora.length - 5);
                        return(pFechaHoraInicial+' '+textoHora);
                    },

    },
  }
</script>